import axios from "axios";

const instance = axios.create({
    baseURL:
        (process.env.NODE_ENV === "production"
            ? "https://a.troubleflight.com"
            : "https://dev-a.ninjatrip.net") + "/api/v3/",
});

// ✅ Log all requests
instance.interceptors.request.use((config) => {
    console.log(`🟢 [AXIOS REQUEST] ${config.method.toUpperCase()} ${config.url}`);
    return config;
});

// ✅ Log all responses (and identify large responses)
instance.interceptors.response.use(
    (response) => {
        try {
            const size = JSON.stringify(response.data).length / 1024; // Size in KB
            console.log(
                `✅ [AXIOS RESPONSE] ${response.config.url} - ${size.toFixed(
                    2
                )} KB`
            );
        } catch (error) {
            console.log(`⚠️ [AXIOS RESPONSE ERROR] Cannot stringify response`);
        }
        return response;
    },
    (error) => {
        // Extract request & response details
        const { config, response } = error;

        console.error(`❌ [AXIOS ERROR] ${config?.url || "Unknown URL"} -`, error.message);

        if (config?.data) {
            console.error(`📡 Request Body: ${JSON.stringify(config.data, null, 2)}`);
        }

        if (response) {
            console.error(`📥 Response Status: ${response.status}`);
            console.error(`📥 Response Headers:`, response.headers);

            try {
                console.error(`📥 Response Body: ${JSON.stringify(response.data, null, 2)}`);
            } catch (err) {
                console.error(`📥 Response Body: (Cannot stringify)`, response.data);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
