export default remote => ({
    create: (type, params) => remote.put(`claim/${type}`, params),
    updateFromCode: (code, data, params) => remote.post(`claim/code/${code}/${data}`, params),
    getFromCode: (code) => remote.get(`claim/code/${code}`),
    getFromId: (id) => remote.get(`claim/id/${id}`),
    getFromInternalCode: (internal_code) => remote.get(`claim/internal_code/${id}`),
    complete: (data, code) => remote.put(`claim/complete/${data}/${code}`),
    verify: (email, internal_code) => remote.get(`claim/verify`, {params: {email, internal_code}}),
    upload: (code, type, file) => remote.post(`claim/upload/${code}/${type}`,
        file, {headers: {'Content-Type': 'multipart/form-data'}}),
    uploadDocument: (code, passenger, document, file) => remote.post(
        `claim/upload-document/${code}/${passenger}/${document}`, file,
        {headers: {'Content-Type': 'multipart/form-data'}}),
    getMandate: (code, data) => remote.get(`claim/download-mandate/${ code }`, {params: data}),
    uploadMandate: (code, file) => remote.post(
        `claim/upload-mandate/${code}`, file,
        {headers: {'Content-Type': 'multipart/form-data'}}),
    verifyClaimID: (claimID) => remote.post(`claim/verify-id/${claimID}`),
    getSignClaim: (claimID, indexes) => remote.get(`claim/sign-claim/${claimID}/${indexes}`),
    postSignClaim: (claimID) => remote.post(`claim/sign-claim/${claimID}`),
    deleteFile: (claimID, file) => remote.delete(`claim/file/${claimID}`, {params: {file}}),

    // Payments
    paymentByCode: (code, security) => remote.get(`claim/payment/by-code/${code}/${security}`),
    savePaymentByCode: (code, security, payment) => remote.post(`claim/payment/by-code/${code}/${security}`, payment)
});
