export default remote => ({
    getContract: (code, format) => remote.get(`claim-contract/${ code }/${ format }`),
    sign: (code, passenger, signature) => remote.post(`claim-contract/sign/${ code }`, { 'signature': signature, passenger }),
    signAll: (code, signatures) => remote.post(`claim-contract/sign-all/${ code }`, { signatures }),
    isSigned: (code) => remote.get(`claim-contract/is-signed/${ code }`),

    getContractClaimByCode: (code) => remote.get(`claim-contract/code-link/${ code }`),
    isSignedLink: (code) => remote.get(`claim-contract/is-signed-link/${ code }`),

    saveSignatures: (code, signatures) => remote.post(`claim-contract/save-signatures/${ code }`, { signatures }),
});
